/* eslint-disable */
/**
 * sizeGuidePopup is a component used on the PDP.
 */
export class sizeGuidePopup {
  constructor() {
    this.init();
  }

  init() {
    const $openPopup = $('.js-guide-popup');
    const $panelWrapper = $('.panel-wrapper');
    const $closePopup = $('.panel-close-button');
    const $body = $('body');

    /**
     * Toggle the popup appearance programmatically and adjusts the scrolling behavior of the page.
     * @param {string} [mode='open'] - The mode indicating whether to open or close the popup.
     *  - 'open' (default): Opens the popup by adding the 'panel-wrapper--opened' class and hides overflow.
     *  - 'close': Closes the popup by removing the 'panel-wrapper--opened' class and restores overflow.
     */
    const togglePopup = (mode = 'open') => {
      // Toggle class to open/close the popup
      $panelWrapper.toggleClass('panel-wrapper--opened', mode !== 'close');
      $body.css('overflow', mode !== 'close' ? 'hidden' : '');
    };

    // Open the popup when the trigger element is clicked
    $openPopup.on('click', () => togglePopup());
    // Close the popup when the close button is clicked
    $closePopup.on('click', () => togglePopup('close'));

    // Close the popup when a click event occurs outside the popup content
    $(document).on('click', event => {
      if ($(event.target).is($panelWrapper)) {
        togglePopup('close');
      }
    });
  }

  // Checks if the sizeGuidePopup is present on the page
  static isOnPage() {
    return $('.js-guide-popup').length > 0;
  }
}