import { Carousel } from './carousel';
/**
 * ProductCarouselFrost class represents a carousel used for frost on PDP.
 */
export class ProductCarouselFrost extends Carousel {
  /**
   * css selector of carousel container
   * @static
   */
  static selector = '.js-product-carousel-frost';

  /**
   * Checks if container node is present in the dom
   * @function isOnPage
   * @returns {Boolean}
   * @static
   */
  static isOnPage() {
    return document.querySelector(ProductCarouselFrost.selector);
  }

  constructor() {
    super();

    this.carousel = new Map();
    if (window.innerWidth < 768) {
      super.init(this, ProductCarouselFrost.selector);
    }
  }

  /**
   * Generate config depending of the number of the slides in carousel by extending the default config
   * @param {Node} $carousel - Carousel DOM Node
   * @returns {Object} extended config
   */
  generateConfig($carousel) {
    if (super.hasSingleSlide($carousel)) {
      return super.extendConfig({
        pagination: false,
        loop: false,
        autoplay: false,
      });
    }

    return super.extendConfig({
      slidesPerView: 1,
      loop: false,
      scrollbar: {
        el: '.swiper-scrollbar',
        hide: false,
        draggable: true
      },
      breakpoints: {
        768: {
          scrollbar: {
            hide: true,
          }
        },
      }
    });
  }
}