/* eslint-disable camelcase */
import { pushToDataLayer } from '../pushToDataLayer';

/**
 * Toggled from accordion.js
 * @param {String} title
 */
export const analyticsAccordionItemOpen = title => {
  pushToDataLayer({
    event: 'engagement',
    action: 'accordion-open',
    label: title
  });
};

/**
 * Toggled from productSample.js
 * @param {String} step - Step number / Confirmation for last step
 * @param {String} selectedItemName
 */
export const analyticsSampleRequest = (step, selectedItemName) => {
  pushToDataLayer({
    event: 'gaEvent',
    eventTrigger: 'sampleRequest',
    eventCategory: 'Sample request',
    eventAction: step,
    eventLabel: selectedItemName
  });
};

/**
 * Tracks a product comparison event in Google Analytics.
 *
 * @param {string} productId - The ID of the selected product.
 * @param {string} selectedProductName - The name of the selected product.
*/
export const analyticsCompareProductListener = (productId, selectedProductName) => {
  pushToDataLayer({
    event: 'ga4_product_comparison',
    ga4_data: {
      action_type: 'Add product',
      single_item_id: productId,
      single_item_name: selectedProductName,
      link_text: selectedProductName
    }
  });
};

/**
 * Events after the form has been sent
 * @param {Node} $form
 */
export const analyticsFormSend = $form => {
  function getProductName() {
    if (window.HMS.ProductSample && window.HMS.ProductSample.productSelectedName
      && window.HMS.ProductSample.productSelectedName.length) {
        return window.HMS.ProductSample.productSelectedName;
    }
    if ($('#AvailableSampleProducts').length) {
      return $('#AvailableSampleProducts').select2('data')[0].text;
    }

    return '';
  }

  switch ($form.data('formtype').toLowerCase()) {
    case 'sampleorder':
      if ($('.js-sample-form-status').length) {
        let status = $('.js-sample-form-status').data('form-status');
        if (status === '') {
          status = 'Blacklisted';
        }
        analyticsSampleRequest(status, getProductName());
      }
      break;
    case 'contactus':
      pushToDataLayer({
        event: 'gaEvent',
        eventTrigger: 'formSubmit',
        eventCategory: 'Form',
        eventAction: 'Submit',
        eventLabel: 'Contact us'
      });
      break;
    case 'askthedoctor':
      pushToDataLayer({
        event: 'engagement',
        action: 'askTheDoctor'
      });
      break;
    case 'newsletter':
      pushToDataLayer({
        event: 'gaEvent',
        eventTrigger: 'formSubmit',
        eventCategory: 'Form',
        eventAction: 'Submit',
        eventLabel: 'Newsletter',
        eventValue: undefined
      });
      break;
    default:
      break;
  }

  if ($form.find('.ga-email-optin').length > 0) {
    pushToDataLayer({
      event: 'gaEvent',
      eventTrigger: 'emailOptIn',
      eventCategory: 'Email Opt-In',
      eventAction: 'Subscribe',
      eventLabel: window.location
    });
  }
}
